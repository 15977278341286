import { useEffect, useState } from "react";
import Slider from "@mui/material/Slider";
import styles from "./NewReport.module.css";
import { ImCross } from "react-icons/im";
import UserSwitch from "../../../commonComponent/UserSwitch";
import { useTranslation } from "react-i18next";

function TimePicker({
  setShowTimePicker,
  hours,
  setHours,
  minutes,
  setMinutes,
  isTimeUnknown,
  setIsTimeUnknown,
}) {
  const { t } = useTranslation();

  useEffect(() => {
    if (hours == "" && minutes == "") {
      setIsTimeUnknown(true);
    } else {
      setIsTimeUnknown(false);
    }
  }, [hours, minutes]);

  // function for handle switch change
  const handleSwitch1 = (event) => {
    const isChecked = event.target.checked;
    setIsTimeUnknown(isChecked);
    if (isChecked) {
      setHours("");
      setMinutes("");
    } else {
      const currentTime = new Date();
      setHours(currentTime.getHours());
      setMinutes(currentTime.getMinutes());
    }
  };

  // function for change hours
  const handleChange1 = (event, newValue) => {
    setHours(newValue);
    if (newValue !== "") {
      setIsTimeUnknown(false);
    }
  };

  // function for change minutes
  const handleChange2 = (event, newValue) => {
    setMinutes(newValue);
    if (newValue !== "") {
      setIsTimeUnknown(false);
    }
  };

  // Function to handle sub-category selection
  const handleCancel = () => {
    setShowTimePicker(false);
  };

  return (
    <div className={styles.timeSlider}>
      <div style={{ textAlign: "end" }}>
        <ImCross onClick={handleCancel} />
      </div>
      <h3>
        {hours == "" ? "00" : String(hours).padStart(2, "0")}:
        {minutes == "" ? "00" : String(minutes).padStart(2, "0")}
      </h3>
      <h6>{t("HOURS")}</h6>

      <div className="SliderSetMuiTime">
        <p style={{ marginRight: "20px" }}>0</p>
        <Slider
          aria-label="Default"
          min={0}
          max={23}
          value={hours == "" ? 0 : hours}
          onChange={handleChange1}
          className="custom-slider"
        />
        <p style={{ marginLeft: "20px" }}>23</p>
      </div>

      <h6>{t("MINUTES")}</h6>

      <div className="SliderSetMuiTime">
        <p style={{ marginRight: "20px" }}>0</p>
        <Slider
          aria-label="Default"
          min={0}
          max={59}
          value={minutes == "" ? 0 : minutes}
          onChange={handleChange2}
          className="custom-slider"
        />
        <p style={{ marginLeft: "20px" }}>59</p>
      </div>

      <div className={styles.unKnownTime}>
        <h5>{t("UNKNOWN")}</h5>
        <UserSwitch
          id="custom-switch1"
          checked={isTimeUnknown}
          onChange={handleSwitch1}
        />
      </div>
    </div>
  );
}
export default TimePicker;
