import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { STATUS_CODES } from "../utils/statusCode";
import Toster from "../utils/Toaster";
import SublyApi from "../helpers/Api";
import { isLimit, offset } from "../utils/Constants";
import styles from "../appComponent/UserModule/YourReport/YourReport.module.css";
import { useTranslation } from "react-i18next";
import { formatTime } from "../utils/manageConstant";
import commentIcon from "../assets/Images/Comment.svg";
import viewIcon from "../assets/Images/Views.svg";
import bookmarkIcon from "../assets/Images/Bookmark.svg";
import trendIcon from "../assets/Images/Trend.svg";
import { Col, Container, Row } from "react-bootstrap";
import { userDetailData, userLogoutClear } from "../store/slices/Userslice";
import { useNavigate } from "react-router-dom";

function SearchList() {
  const { userData, userToken, searchQuery } = useSelector(
    (state) => state.user
  );
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [search, setSearch] = useState("");
  const [loader, setLoader] = useState(false);
  const [showDataList, setShowDataList] = useState([]);
  const [totalReports, setTotalReports] = useState();
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    userToken && dispatch(userDetailData(userToken));
  }, []);

  useEffect(() => {
    if (searchQuery) {
      searchReports(searchQuery);
      userReport();
    } else {
      userReport();
    }
  }, [searchQuery]);

  async function userReport(page = 1) {
    setLoader(true);
    await SublyApi.getUserReport(
      userToken,
      isLimit,
      offset + (page - 1) * isLimit
    ).then((response) => {
      setLoader(false);
      const errormsg = response && response.data && response.data.message;
      if (response && response.status_code === STATUS_CODES.SUCCESS) {
        const sortedReports = response.data.reports;

        setTotalReports(response.data);
        if (sortedReports && sortedReports.length > 0) {
          if (page === 1) {
            setShowDataList(sortedReports);
            setFilteredData(sortedReports);
          } else {
            setShowDataList((prev) => [...prev, ...sortedReports]);
            setFilteredData((prev) => [...prev, ...sortedReports]);
          }
        } else {
          setShowDataList([]);
        }
      } else if (
        (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
          response?.data?.error_type === "ACCOUNT_DISABLED") ||
        (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
          response?.data?.error_type === "SESSION_EXPIRED") ||
        (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
          response?.data?.error_type === "INVALID_TOKEN")
      ) {
        dispatch(userLogoutClear());
        navigate("/");
      } else {
        Toster(t(errormsg), "error");
      }
    });
  }

  async function searchReports(query) {
    setLoader(true);
    await SublyApi.reportSearchList(
      userToken,
      isLimit,
      offset,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      query
    ).then((response) => {
      setLoader(false);
      if (response.status_code === STATUS_CODES.SUCCESS) {
        const filteredReports = response.data.reports?.filter(
          (report) => report.my_reports === true
        );
        setShowDataList(filteredReports);
      } else if (
        (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
          response?.data?.error_type === "ACCOUNT_DISABLED") ||
        (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
          response?.data?.error_type === "SESSION_EXPIRED") ||
        (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
          response?.data?.error_type === "INVALID_TOKEN")
      ) {
        dispatch(userLogoutClear());
        navigate("/");
      } else {
        Toster(response.data.message, "error");
      }
    });
  }

  return (
    <div className="main">
      <div className="spaceTopSet">
        <Container>
          <Row>
            <Col lg={9} md={8} className="rightPart">
              <div className={styles.scrollTopYourReport}>
                {showDataList && showDataList.length > 0 ? (
                  showDataList.map((item, index) => (
                    <div className={styles.reportSection} key={index}>
                      <div className={styles.reportSectionLeft}>
                        <div className={styles.reportImg}>
                          <img src={item.profile_picture} alt="img" />
                        </div>
                        <div className={styles.reportText}>
                          <p>
                            <strong>{item.category_name}</strong>
                            {item.location != "undefined" && (
                              <> &nbsp; - &nbsp;</>
                            )}
                            {item.location == "undefined" ? "" : item.location}{" "}
                            &nbsp; - &nbsp;
                            <span>
                              {item.date} {t("At")}{" "}
                              {item.time ? formatTime(item.time) : t("UNKNOWN")}
                            </span>
                          </p>
                          <div className={styles.reportShow}>
                            <p>
                              <img src={commentIcon} alt="comment icon" />
                              &nbsp;
                              {item.comment_count == ""
                                ? 0
                                : item.comment_count}
                            </p>
                            <p>
                              <img src={viewIcon} alt="view icon" />
                              &nbsp;
                              {item.view_count == "" ? 0 : item.view_count}
                            </p>
                            <p>
                              <img src={bookmarkIcon} alt="bookmark icon" />
                              &nbsp;
                              {item.bookmark_count == ""
                                ? 0
                                : item.bookmark_count}
                            </p>
                            <p>
                              <img src={trendIcon} alt="trend icon" />
                              &nbsp;
                              {item.trend_count == "" ? 0 : item.trend_count}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="records_Found">
                    <h2>{t("NO_REC_FOUND")}</h2>
                  </div>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default SearchList;
