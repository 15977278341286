import { Col, Container, Form, Row } from "react-bootstrap";
import Sidebar from "../Sidebar";
import styles from "./Notifications.module.css";
import { useTranslation } from "react-i18next";
import UserSwitch from "../../../commonComponent/UserSwitch";
import { STATUS_CODES } from "../../../utils/statusCode";
import Toster from "../../../utils/Toaster";
import SublyApi from "../../../helpers/Api";
import { useDispatch, useSelector } from "react-redux";
import qs from "qs";
import { NOTIFICATION_TYPE } from "../../../utils/Constants";
import { useEffect, useState } from "react";
import {
  updateEmailAlert,
  userDetailData,
  userLogoutClear,
} from "../../../store/slices/Userslice";
import { useNavigate } from "react-router-dom";

//---function for user email notifications---------
function EmailNotification() {
  const { userToken, userData } = useSelector((state) => state.user);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [emailAlert, setEmailAlert] = useState(userData?.email_alert == 1);
  // const [disable, setDisable] = useState(false);

  useEffect(() => {
    if (userData && userData?.email_alert !== undefined) {
      setEmailAlert(userData?.email_alert == 1);
    }
  }, [userData]);

  //======= function for manage email notification=======

  useEffect(() => {
    userToken && dispatch(userDetailData(userToken));
  }, []);

  const handleChange = async (checked) => {
    const emailObject = {
      alert_type: NOTIFICATION_TYPE.EMAIL,
      status: checked ? 1 : 0,
    };
    const urlEncodedData = qs.stringify(emailObject);
    // setDisable(true);
    await SublyApi.emailNotification(userToken, urlEncodedData).then(
      (responsejson) => {
        // setDisable(false);
        if (responsejson.status_code === STATUS_CODES.SUCCESS) {
          Toster(responsejson.message, "success");
          // setEmailAlert(checked);
          dispatch(updateEmailAlert(checked ? 1 : 0));
        } else if (
          (responsejson?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
            responsejson?.data?.error_type === "ACCOUNT_DISABLED") ||
          (responsejson?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
            responsejson?.data?.error_type === "SESSION_EXPIRED") ||
          (responsejson?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
            responsejson?.data?.error_type === "INVALID_TOKEN")
        ) {
          dispatch(userLogoutClear());
          navigate("/");
        } else {
          Toster(t(responsejson.data.message), "error");
        }
      }
    );
  };

  return (
    <div className="main">
      <div className="spaceTopSet">
        <Container>
          <Row>
            <Col lg={3} md={4}>
              <Sidebar />
            </Col>
            <Col lg={9} md={8} className="rightPart">
              <div className="rightTopPart">
                <h2>{t("EMAIL_NOTIFY")}</h2>
              </div>

              <div className={styles.notification}>
                <h3>{t("EMAIL_NOTIFICATION")}</h3>
                <div className={styles.crimeNotify}>
                  <p>
                    {t("SEND_EMAIL")} - <span> {userData.email} </span>
                  </p>
                </div>
                <div className={styles.crimeNotify}>
                  <div className={styles.crimeNotifySpace}>
                    <h5>{t("CRIME_NOTIFY")}</h5>
                    <p>{t("ALERT_CRIME")}</p>
                  </div>
                  <UserSwitch
                    id="custom-switch1"
                    checked={emailAlert}
                    onChange={(e) => handleChange(e.target.checked)}
                    // disabled={disable}
                  />
                </div>
                {/* <div className={styles.crimeNotify}>
                    <div className={styles.crimeNotifySpace}>
                      <h5>{t("SUSPICIOUS")}</h5>
                      <p>{t("ALERT_SUSPIOUS")}</p>
                    </div>
                    <UserSwitch
                      id="custom-switch2"
                      checked={suspiciousNotify}
                      onChange={handleSwitch2}
                    />
                  </div>
                  <div className={styles.crimeNotify}>
                    <div>
                      <h5>{t("ALERT_RADIUS")}</h5>
                      <p>{t("SELECT_DIST")}</p>
                    </div>
                  </div>
                  <DistanceCommonSlider
                    min={1}
                    max={8}
                    value={value}
                    onChange={handleChange}
                    valueLabelFormat={(value) => `${value} km`}
                  />
                  <div className={styles.crimeNotify}>
                    <UserCommonButton type="button" label={t("CANCEL")} />
                    <UserCommonButton type="submit" label={t("SUBMIT")} />
                  </div> */}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}
export default EmailNotification;
