import { Col, Container, Row } from "react-bootstrap";
import styles from "./Content.module.css";
import { useTranslation } from "react-i18next";
import SublyApi from "../../helpers/Api";
import { CONTENT_TYPE } from "../../utils/Constants";
import { useDispatch, useSelector } from "react-redux";
import Toster from "../../utils/Toaster";
import { STATUS_CODES } from "../../utils/statusCode";
import { useEffect, useState } from "react";
import { TextFormate } from "../../utils/removeExtraPlusSigns";
import Loader from "../../utils/Loader/Loader";
import { useNavigate } from "react-router-dom";
import {
  updateSettingCount,
  userLogoutClear,
} from "../../store/slices/Userslice";

function AcceptTerms() {
  const dispatch = useDispatch();
  const { userToken, userData, currentUser } = useSelector(
    (state) => state.user
  );

  const { t } = useTranslation();
  const navigate = useNavigate();
  const [termsData, setTermsData] = useState();
  const [loader, setLoader] = useState(false);

  // function for get terms and condition api calling
  async function termsCondition() {
    setLoader(true);
    await SublyApi.contentType(CONTENT_TYPE.TERMS_CONDITION).then(
      (response) => {
        setLoader(false);
        const errormsg = response && response.data && response.data.message;
        if (response && response.status_code === STATUS_CODES.SUCCESS) {
          setTermsData(
            response &&
              response.data &&
              response.data.content &&
              response.data.content.terms
          );
        } else {
          Toster(t(errormsg), "error");
        }
      }
    );
  }

  useEffect(() => {
    termsCondition();
  }, []);

  async function AcceptTermsfunction() {
    setLoader(true);
    await SublyApi.AcceptTerms(userToken).then((response) => {
      setLoader(false);
      const errormsg = response && response.data && response.data.message;
      if (response && response.status_code === STATUS_CODES.SUCCESS) {
        dispatch(
          updateSettingCount({
            keyName: "is_terms_accepted",
            value: 1,
          })
        );
        navigate("/dashboard");
      } else if (
        (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
          response?.data?.error_type === "ACCOUNT_DISABLED") ||
        (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
          response?.data?.error_type === "SESSION_EXPIRED") ||
        (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
          response?.data?.error_type === "INVALID_TOKEN")
      ) {
        dispatch(userLogoutClear());
        navigate("/");
      } else {
        Toster(t(errormsg), "error");
      }
    });
  }

  return (
    <div className="main">
      {loader && <Loader />}
      <div className="spaceTopSet">
        <Container>
          <Row>
            <Col lg={12} md={12}>
              <div className="reportIncidentOverlay">
                <div className={styles.termsConditionMainDiv}>
                  <div className={styles.termsHeading}>
                    <p>{t("TERMS_CONDTIONS")}</p>
                  </div>
                  <p
                    className={styles.termsCondition}
                    dangerouslySetInnerHTML={{
                      __html: TextFormate(termsData),
                    }}
                  ></p>
                  <div className={styles.termsConditionButtion}>
                    <button
                      onClick={() => {
                        AcceptTermsfunction();
                      }}
                    >
                      {t("ACCEPT")}
                    </button>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default AcceptTerms;
