import { useEffect, useRef, useState } from "react";
import {
  Container,
  Form,
  Tooltip,
  Overlay,
  Button,
  Row,
  Col,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import SublyApi from "../../helpers/Api";
import { STATUS_CODES } from "../../utils/statusCode";
import Toster from "../../utils/Toaster";
import { useSelector } from "react-redux";
import { isLimit, LOCATION, offset } from "../../utils/Constants";
import Loader from "../../utils/Loader/Loader";
import commentIcon from "../../../src/assets/Images/Comment.svg";
import viewIcon from "../../../src/assets/Images/Views.svg";
import bookmarkIcon from "../../../src/assets/Images/Bookmark.svg";
import trendIcon from "../../../src/assets/Images/Trend.svg";
import yourReportStyles from "../UserModule/YourReport/YourReport.module.css";
import mapListStyles from "../MapList/MapList.module.css";
import { formatTime } from "../../utils/manageConstant";
import { useNavigate } from "react-router-dom";
import { Autocomplete } from "@react-google-maps/api";
import { IoSearchSharp } from "react-icons/io5";
import { MdArrowDropDown } from "react-icons/md";
import moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";
import MultipleCategoryModal from "../MapList/MultipleCategoryModal";
import defaultImage from "../../assets/Images/user_profile_Default_image.png";

const TimelineGuest = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [timeLineData, setTimeLineData] = useState([]);
  const [page, setPage] = useState(1);
  const autocomplete = useRef(null);
  const [center, setCenter] = useState({ location: "", lat: 0, lng: 0 });
  const [show, setShow] = useState(false);
  const [target, setTarget] = useState(null);
  const [selectedSubCategory, setSelectedSubCategory] = useState("");
  const [crimesCategory, setCrimesCategory] = useState([]);
  const [startDate, setStartDate] = useState(moment().subtract(89, "days"));
  const [endDate, setEndDate] = useState(moment());
  const [startDateSelect, setStartDateSelect] = useState(
    startDate.format("YYYY-MM-DD")
  );
  const [endDateSelect, setEndDateSelect] = useState(
    endDate.format("YYYY-MM-DD")
  );
  const [selectedRange, setSelectedRange] = useState("");
  const [totalReports, setTotalReports] = useState("");

  const [searchAddress, setAddressSearch] = useState("");

  useEffect(() => {
    const selectedIds =
      selectedSubCategory && selectedSubCategory.length > 0
        ? selectedSubCategory.map((subCategory) => subCategory.id).join(",")
        : "";

    TimeLineList(
      1,
      center.lat,
      center.lng,
      startDateSelect,
      endDateSelect,
      selectedIds,
      searchAddress
    );
  }, [center]);

  const handleApply = async (event, picker) => {
    const start = picker.startDate.format("YYYY-MM-DD");
    const end = picker.endDate.format("YYYY-MM-DD");
    setStartDateSelect(start);
    setEndDateSelect(end);

    if (picker.chosenLabel) {
      const days = parseInt(picker.chosenLabel.match(/\d+/));
      if (!isNaN(days)) {
        setSelectedRange(days);
      }
    }

    const selectedIds =
      selectedSubCategory && selectedSubCategory.length > 0
        ? selectedSubCategory.map((subCategory) => subCategory.id).join(",")
        : "";

    // Call mapListShow with the formatted dates
    await TimeLineList(
      1,
      center.lat,
      center.lng,
      start,
      end,
      selectedIds,
      searchAddress
    );
    setPage(1);
  };

  const ranges = {
    "Last 90 Days": [moment().subtract(89, "days"), moment()],
    "Last 60 Days": [moment().subtract(59, "days"), moment()],
    "Last 30 Days": [moment().subtract(29, "days"), moment()],
    "Last Month": [
      moment().subtract(1, "month").startOf("month"),
      moment().subtract(1, "month").endOf("month"),
    ],
  };

  function setTimeLineLatLong() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setCenter({ lat: latitude, lng: longitude });
        },
        (error) => {
          setCenter({
            lat: LOCATION.LAT,
            lng: LOCATION.LONG,
          });
          console.error("Error obtaining geolocation: ", error);
        }
      );
    } else {
      setCenter({
        lat: LOCATION.LAT,
        lng: LOCATION.LONG,
      });
    }
  }
  useEffect(() => {
    // Get current location
    setTimeLineLatLong();
  }, []);

  const handleAddressSelect = (place) => {
    if (place.geometry) {
      const latitude = place.geometry.location.lat();
      const longitude = place.geometry.location.lng();
      const location = place.formatted_address;
      setAddressSearch(location);
      setCenter({ lat: latitude, lng: longitude, location: location });
    }
  };

  // function for get category list api calling
  async function crimeCategory() {
    await SublyApi.crimeCategoryList("").then((response) => {
      const errormsg = response && response.data && response.data.message;
      if (response && response.status_code === STATUS_CODES.SUCCESS) {
        setCrimesCategory(response && response.data);
      } else {
        Toster(t(errormsg), "error");
      }
    });
  }

  //   getTimeline
  async function TimeLineList(
    pageNo = 1,
    latitude = "",
    longitude = "",
    sdate = "",
    edate = "",
    categoryId = ""
  ) {
    setLoader(true);
    latitude &&
      longitude &&
      (await SublyApi.getTimelineGuest(
        isLimit,
        offset + (pageNo - 1) * isLimit,
        latitude,
        longitude,
        sdate,
        edate,
        categoryId
      ).then((response) => {
        setLoader(false);
        const errormsg = response && response.data && response.data.message;
        if (response && response.status_code === STATUS_CODES.SUCCESS) {
          const sortedReports = response?.data?.timeline;
          setTotalReports(response?.data?.total_timelines);
          if (sortedReports && sortedReports.length > 0) {
            if (pageNo === 1) {
              setTimeLineData(sortedReports);
            } else {
              setTimeLineData((prev) => [...prev, ...sortedReports]);
            }
          } else {
            setTimeLineData([]);
          }
        } else {
          Toster(t(errormsg), "error");
        }
      }));
  }

  useEffect(() => {
    crimeCategory();
  }, []);

  const TimeLineViewDetails = (id) => {
    navigate(`/report/${id}`);
  };

  const loadMoreReports = () => {
    const selectedIds =
      selectedSubCategory && selectedSubCategory.length > 0
        ? selectedSubCategory.map((subCategory) => subCategory.id).join(",")
        : "";
    const pageNo = page;
    TimeLineList(
      pageNo + 1,
      center.lat,
      center.lng,
      startDateSelect,
      endDateSelect,
      selectedIds,
      searchAddress
    );
    setPage((prevPage) => prevPage + 1);
  };

  const checkAddress = (e) => {
    if (e.target.value == "") {
      setTimeLineLatLong();
      setAddressSearch("");
    }
  };

  return (
    <div className="main">
      {loader && <Loader />}
      {/* filter design */}
      <div>
        <Container>
          <Row className="fullSpace">
            <Col lg={4} md={12} sm={12}>
              {" "}
              <div className={mapListStyles.location}>
                <Autocomplete
                  placeApiKey={process.env.REACT_APP_GOOGLE_PLACE_API_KEY}
                  onLoad={(auto) => {
                    autocomplete.current = auto;
                  }}
                  onPlaceChanged={() => {
                    const place = autocomplete.current.getPlace();
                    handleAddressSelect(place);
                  }}
                >
                  <div className={mapListStyles.searchDiv}>
                    <Form.Control
                      type="text"
                      onChange={checkAddress}
                      placeholder={t("SEARCH_ADDRESS")}
                    />
                    <IoSearchSharp />
                  </div>
                </Autocomplete>
              </div>
            </Col>

            <Col lg={4} md={12} sm={12}>
              <div className="datepickerRange">
                <DateRangePicker
                  initialSettings={{
                    startDate: startDate.toDate(),
                    endDate: endDate.toDate(),
                    ranges: ranges,
                    opens: "right",
                    alwaysShowCalendars: true,
                    maxDate: moment().toDate(),
                    locale: {
                      format: "DD/MM/YYYY",
                    },
                  }}
                  onApply={handleApply}
                >
                  <input type="text" className="form-control" />
                </DateRangePicker>
              </div>
            </Col>

            <Col lg={4} md={12} sm={12}>
              {" "}
              <div className={`${mapListStyles.reportSelect} tooltipCls`}>
                <Button
                  ref={target}
                  onClick={(event) => {
                    setShow(!show);
                    setTarget(event.target);
                  }}
                >
                  <div className={mapListStyles.reportManage}>
                    <MdArrowDropDown />
                    <p>{t("ALL_CRIME")}</p>
                  </div>
                </Button>
                <Overlay
                  target={target}
                  show={show}
                  rootClose={true}
                  placement="bottom"
                  onHide={() => setShow(false)}
                >
                  {(props) => (
                    <Tooltip
                      id="overlay-example"
                      {...props}
                      className="tooltipSetCrime multipleCrime"
                    >
                      <MultipleCategoryModal
                        setShow={setShow}
                        startDateSelect={startDateSelect}
                        selectedRange={selectedRange}
                        endDateSelect={endDateSelect}
                        crimesCategory={crimesCategory}
                        setSelectedSubCategory={setSelectedSubCategory}
                        selectedSubCategory={selectedSubCategory}
                        mapListShow={TimeLineList}
                        center={center}
                        setPage={setPage}
                      />
                    </Tooltip>
                  )}
                </Overlay>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div>
        <Container>
          <Row>
            <Col lg={8} md={8}>
              <div className={yourReportStyles.scrollTopTimeline}>
                {timeLineData && timeLineData.length > 0 ? (
                  timeLineData.map((item, index) => (
                    <div className={yourReportStyles.reportSection} key={index}>
                      <div
                        className={yourReportStyles.reportSectionLeft}
                        // onClick={() => TimeLineViewDetails(item.reportID)}
                      >
                        <div
                          className={yourReportStyles.reportImg}
                          onClick={() => TimeLineViewDetails(item.reportID)}
                        >
                          <img
                            src={
                              item.profile_picture
                                ? item.profile_picture
                                : defaultImage
                            }
                            alt="img"
                          />
                        </div>
                        <div
                          className={yourReportStyles.reportText}
                          onClick={() => TimeLineViewDetails(item.reportID)}
                        >
                          <p>
                            <strong>
                              {item.category_name == undefined ||
                              item.category_name == "" ? (
                                ""
                              ) : (
                                <>
                                  {item.category_name}
                                  &nbsp; - &nbsp;
                                </>
                              )}
                            </strong>

                            {item.location == undefined ||
                            item.location == "" ? (
                              ""
                            ) : (
                              <>
                                {item.location}
                                &nbsp; - &nbsp;
                              </>
                            )}

                            <span>
                              {item.date} {t("At")}{" "}
                              {item.time ? formatTime(item.time) : t("UNKNOWN")}
                            </span>
                          </p>
                          <div className={yourReportStyles.reportShow}>
                            <p>
                              <img src={commentIcon} alt="comment icon" />
                              &nbsp;
                              {item.comment_count == ""
                                ? 0
                                : item.comment_count}
                            </p>
                            <p>
                              <img src={viewIcon} alt="view icon" />
                              &nbsp;
                              {item.view_count == "" ? 0 : item.view_count}
                            </p>
                            <p>
                              <img src={bookmarkIcon} alt="bookmark icon" />
                              &nbsp;
                              {item.bookmark_count == ""
                                ? 0
                                : item.bookmark_count}
                            </p>
                            <p>
                              <img src={trendIcon} alt="trend icon" />
                              &nbsp;
                              {item.trend_count == "" ? 0 : item.trend_count}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <>
                    <div className="records_Found spaceRemove">
                      <h2>{t("NO_REC_FOUND")}</h2>
                    </div>
                  </>
                )}

                {timeLineData?.length < totalReports ? (
                  <div className="loadMoreSection">
                    <button className="loadMoreBtn" onClick={loadMoreReports}>
                      {t("LOAD_MORE")}
                    </button>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </Col>
            <Col lg={4} md={4}>
              <div className={yourReportStyles.reportRightAd}>
                Google Adsense Advert 336x280
              </div>{" "}
              <div className={yourReportStyles.reportRightAd}>
                Google Adsense Advert 336x280
              </div>{" "}
              <div className={yourReportStyles.reportRightAd}>
                Google Adsense Advert 336x280
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default TimelineGuest;
