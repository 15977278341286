import { Col, Container, Form, Row } from "react-bootstrap";
import Sidebar from "../Sidebar";
import styles from "../YourReport/YourReport.module.css";
import { useTranslation } from "react-i18next";
import { useCallback, useEffect, useState } from "react";
import commentIcon from "../../../assets/Images/Comment.svg";
import viewIcon from "../../../assets/Images/Views.svg";
import bookmarkIcon from "../../../assets/Images/Bookmark.svg";
import trendIcon from "../../../assets/Images/Trend.svg";
import UserSearchModule from "../../../commonComponent/UserSearchInput";
import CrudIcon from "../../../commonComponent/CrudIcon";
import { useDispatch, useSelector } from "react-redux";
import { STATUS_CODES } from "../../../utils/statusCode";
import Toster from "../../../utils/Toaster";
import SublyApi from "../../../helpers/Api";
import Loader from "../../../utils/Loader/Loader";
import { useNavigate } from "react-router-dom";
import { formatTime } from "../../../utils/manageConstant";
import { isLimit, offset } from "../../../utils/Constants";
import {
  userDetailData,
  userLogoutClear,
} from "../../../store/slices/Userslice";

const filterOptions = [
  { value: "viewbyDate", text: "View by Date" },
  { value: "mostViewed", text: "Most Viewed" },
  { value: "mostCommented", text: "Most Commented" },
  { value: "mostBookmarked", text: "Most Bookmarked" },
  { value: "mostTrending", text: "Most Trending" },
];

function RecentlyViewed() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [filter, setFilter] = useState("");
  const { userToken, userData } = useSelector((state) => state.user);
  const [loader, setLoader] = useState(false);
  const [viewedList, setViewedList] = useState([]);
  const [totalReports, setTotalReports] = useState();
  const [filteredData, setFilteredData] = useState([]);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");

  useEffect(() => {
    userToken && dispatch(userDetailData(userToken));
  }, []);

  async function RecentlyViewedData(userID, page = 1) {
    setLoader(true);
    await SublyApi.RecentlyViewedTooltip(
      userToken,
      userID,
      isLimit,
      offset + (page - 1) * isLimit
    ).then((response) => {
      setLoader(false);
      const errormsg = response && response.data && response.data.message;
      if (response && response.status_code === STATUS_CODES.SUCCESS) {
        const sortedReports = response.data.viewReports;
        setTotalReports(response?.data?.total_viewReports);
        if (sortedReports && sortedReports.length > 0) {
          if (page === 1) {
            setViewedList(sortedReports);
            setFilteredData(sortedReports);
          } else {
            setViewedList((prev) => [...prev, ...sortedReports]);
            setFilteredData((prev) => [...prev, ...sortedReports]);
          }
        } else {
          setViewedList([]);
        }
      } else if (
        (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
          response?.data?.error_type === "ACCOUNT_DISABLED") ||
        (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
          response?.data?.error_type === "SESSION_EXPIRED") ||
        (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
          response?.data?.error_type === "INVALID_TOKEN")
      ) {
        dispatch(userLogoutClear());
        navigate("/");
      } else {
        Toster(t(errormsg), "error");
      }
    });
  }

  useEffect(() => {
    RecentlyViewedData(userData.userID);
  }, [userData.userID]);

  async function SearchList(text = "") {
    setLoader(true);
    await SublyApi.reportSearchList(
      userToken,
      isLimit,
      offset,
      true,
      true,
      false,
      false,
      false,
      true,
      false,
      text
    ).then((response) => {
      setLoader(false);
      const errormsg = response && response.data && response.data.message;
      if (response && response.status_code === STATUS_CODES.SUCCESS) {
        setViewedList(response.data.reports);
      } else if (
        (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
          response?.data?.error_type === "ACCOUNT_DISABLED") ||
        (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
          response?.data?.error_type === "SESSION_EXPIRED") ||
        (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
          response?.data?.error_type === "INVALID_TOKEN")
      ) {
        dispatch(userLogoutClear());
        navigate("/");
      } else {
        Toster(t(errormsg), "error");
      }
    });
  }

  const applyFilter = (data, selectedCategory) => {
    let sortedData = [...data];
    if (selectedCategory === "viewbyDate") {
      sortedData.sort((a, b) => new Date(b.date) - new Date(a.date));
    } else if (selectedCategory === "mostViewed") {
      sortedData.sort((a, b) => b.view_count - a.view_count);
    } else if (selectedCategory === "mostCommented") {
      sortedData.sort((a, b) => b.comment_count - a.comment_count);
    } else if (selectedCategory === "mostBookmarked") {
      sortedData.sort((a, b) => b.bookmark_count - a.bookmark_count);
    } else if (selectedCategory === "mostTrending") {
      sortedData.sort((a, b) => b.trend_count - a.trend_count);
    }
    return sortedData;
  };

  const ChangeCategory = (e) => {
    const selectedCategory = e.target.value;
    setFilter(selectedCategory);
    setFilteredData(applyFilter(viewedList, selectedCategory));
    setViewedList(applyFilter(viewedList, selectedCategory));
  };

  const TimeLineViewProfile = (id) => {
    navigate(`/profile-detail/${id}`);
  };

  const ReportViewDetails = (id) => {
    navigate(`/report/${id}`);
  };

  const handleSearch = (e) => {
    const searchText = e.target.value;
    setSearch(searchText);

    if (searchText) {
      SearchList(searchText);
    } else {
      RecentlyViewedData(userData.userID);
    }
  };

  const loadMoreReports = () => {
    setPage((prevPage) => prevPage + 1);
    RecentlyViewedData(userData.userID, page + 1);
  };

  return (
    <>
      <div className="main">
        {loader && <Loader />}
        <div className="spaceTopSet">
          <Container>
            <Row>
              <Col lg={3} md={4}>
                <Sidebar totalReports={totalReports} />
              </Col>

              <Col lg={9} md={8} className="rightPart">
                <div className="rightTopPart">
                  <h2>{t("RECENTLY_VIEWED_REPORT")}</h2>
                  <UserSearchModule hadleSearch={handleSearch} value={search} />
                </div>
                <div className={styles.datefilter}>
                  <div
                    className={`dropdown-container ${styles.dropdownContainer}`}
                  >
                    <Form.Select
                      className={`dropdown ${styles.inputSelect}`}
                      value={filter}
                      onChange={ChangeCategory}
                    >
                      {filterOptions &&
                        filterOptions.map((item, index) => (
                          <option key={index} value={item.value}>
                            {item.text}
                          </option>
                        ))}
                    </Form.Select>
                    <span className={`dropdown-icon ${styles.dropdownIcon}`}>
                      &#9660;
                    </span>
                  </div>
                </div>

                <div className={styles.scrollTopYourReport}>
                  {viewedList && viewedList.length > 0 ? (
                    viewedList.map((item, index) => (
                      <div className={styles.reportSection} key={index}>
                        <div className={styles.reportSectionLeft}>
                          <div
                            className={styles.reportImg}
                            onClick={() => TimeLineViewProfile(item.userID)}
                          >
                            <img src={item.profile_picture} alt="img" />
                          </div>
                          <div
                            className={styles.reportText}
                            onClick={() => ReportViewDetails(item.reportID)}
                          >
                            <p>
                              <strong>{item.category_name}</strong>
                              {item.location !== "undefined" && (
                                <> &nbsp; - &nbsp;</>
                              )}
                              {item.location !== "undefined"
                                ? item.location
                                : ""}{" "}
                              &nbsp; - &nbsp;
                              <span>
                                {item.date} {t("At")}{" "}
                                {item.time
                                  ? formatTime(item.time)
                                  : t("UNKNOWN")}
                              </span>
                            </p>
                            <div className={styles.reportShow}>
                              <p>
                                <img src={commentIcon} alt="comment icon" />
                                &nbsp;&nbsp;
                                {item.comment_count || 0}
                              </p>
                              <p>
                                <img src={viewIcon} alt="view icon" />
                                &nbsp;&nbsp;
                                {item.view_count || 0}
                              </p>
                              <p>
                                <img src={bookmarkIcon} alt="bookmark icon" />
                                &nbsp;&nbsp;
                                {item.bookmark_count || 0}
                              </p>
                              <p>
                                <img src={trendIcon} alt="trend icon" />
                                &nbsp;&nbsp;
                                {item.trend_count || 0}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className={styles.crudIconBookmarkHover}>
                          <CrudIcon />
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="records_Found">
                      <h2>{t("NO_REC_FOUND")}</h2>
                    </div>
                  )}

                  {viewedList?.length < totalReports ? (
                    <div className="loadMoreSection">
                      <button className="loadMoreBtn" onClick={loadMoreReports}>
                        {t("LOAD_MORE")}
                      </button>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
}

export default RecentlyViewed;
