import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Sidebar from "../Sidebar";
import { useTranslation } from "react-i18next";
import styles from "./ConnectTwitter.module.css";
import { FaXTwitter } from "react-icons/fa6";
import SublyApi from "../../../helpers/Api";
import Loader from "../../../utils/Loader/Loader";
import { STATUS_CODES } from "../../../utils/statusCode";
import Toster from "../../../utils/Toaster";
import { useDispatch, useSelector } from "react-redux";
import {
  setUpdateUserData,
  userDetailData,
  userLogoutClear,
} from "../../../store/slices/Userslice";
import { useNavigate } from "react-router-dom";

const ConnectTwitter = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userToken, userData } = useSelector((state) => state.user);
  const [loader, setLoader] = useState(false);

  async function twitterAuthorisation() {
    setLoader(true);
    await SublyApi.ConnectTwitterAuth(userToken).then(async (response) => {
      setLoader(false);
      const errormsg = response && response.data && response.data.message;

      if (response && response.status_code === STATUS_CODES.SUCCESS) {
        localStorage.setItem("twitterLoginFrom", "connectTwitter");
        window.location.href = response?.data?.authorize_url;
      } else if (
        (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
          response?.data?.error_type === "ACCOUNT_DISABLED") ||
        (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
          response?.data?.error_type === "SESSION_EXPIRED") ||
        (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
          response?.data?.error_type === "INVALID_TOKEN")
      ) {
        dispatch(userLogoutClear());
        navigate("/");
      } else {
        Toster(t(errormsg), "error");
      }
    });
  }

  useEffect(() => {
    userToken && dispatch(userDetailData(userToken));
  }, []);

  async function disconnectTwitter() {
    setLoader(true);
    await SublyApi.DisConnectTwitter(userToken).then(async (response) => {
      setLoader(false);
      const errormsg = response?.data?.message;

      if (response && response.status_code === STATUS_CODES.SUCCESS) {
        dispatch(setUpdateUserData(response?.data?.twitter_connect_username));
        // setUpdateUserData((prevUserData) => ({
        //   ...prevUserData,
        //   twitter_connect_username: response?.data?.twitter_connect_username,
        // }));
      } else if (
        (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
          response?.data?.error_type === "ACCOUNT_DISABLED") ||
        (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
          response?.data?.error_type === "SESSION_EXPIRED") ||
        (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
          response?.data?.error_type === "INVALID_TOKEN")
      ) {
        dispatch(userLogoutClear());
        navigate("/");
      } else {
        Toster(t(errormsg), "error");
      }
    });
  }

  return (
    <div className="main">
      {loader && <Loader />}
      <div className="spaceTopSet">
        <Container>
          <Row>
            <Col lg={3} md={4}>
              <Sidebar />
            </Col>
            <Col lg={9} md={8} className="rightPart">
              <div className="rightTopPart">
                <h2>{t("CONNECT_TWITTER")}</h2>
              </div>
              <div className={styles.connectTwitterMain}>
                <div className={styles.connectTwitteInnerOne}>
                  <h3>{t("CONNECT_TWITTER_HEADING")}</h3>
                </div>
                <hr className={styles.hrborder} />
                <div className={styles.connectTwitteAccount}>
                  <div className={styles.connectTwitteAccountInner}>
                    <div className={styles.twitterSocialIcon}>
                      <FaXTwitter />
                    </div>
                    {userData.twitter_connect_username ? (
                      <h3>{userData.twitter_connect_username}</h3>
                    ) : (
                      <h3>{t("CONNECT_YOUR_ACCOUNT")}</h3>
                    )}
                  </div>
                  {userData.twitter_connect_username ? (
                    <button
                      onClick={disconnectTwitter}
                      className={styles.followButtonadd}
                    >
                      {t("DISCONNECT")}
                    </button>
                  ) : (
                    <button
                      onClick={twitterAuthorisation}
                      className={styles.followButtonadd}
                    >
                      {t("CONNECT")}
                    </button>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default ConnectTwitter;
