import { useEffect, useRef, useState } from "react";
import {
  Container,
  Form,
  Tooltip,
  Overlay,
  Button,
  Row,
  Col,
} from "react-bootstrap";
import { Autocomplete } from "@react-google-maps/api";
import { useTranslation } from "react-i18next";
import MapView from "../../commonComponent/GoogleMap/MapView";
import styles from "./MapList.module.css";
import { IoSearchSharp } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import SublyApi from "../../helpers/Api";
import Toster from "../../utils/Toaster";
import { STATUS_CODES } from "../../utils/statusCode";
import { MdArrowDropDown } from "react-icons/md";
import moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import MultipleCategoryModal from "./MultipleCategoryModal";
import { useNavigate } from "react-router-dom";
import CurrentLocation from "../../assets/Images/Combined Shape.svg";
import { formatTime } from "../../utils/manageConstant";
import { FLAG, LOCATION } from "../../utils/Constants";
import qs from "qs";
import { userDetailData, userLogoutClear } from "../../store/slices/Userslice";
import MapViewZoom from "../../commonComponent/GoogleMap/MapviewZoom";

function MapListReport() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { userToken } = useSelector((state) => state.user);
  const autocomplete = useRef(null);
  const [mapList, setMapList] = useState("");
  const [mapRecentList, setMapRecentList] = useState("");
  const [center, setCenter] = useState({
    location: "",
    lat: 0.0,
    lng: 0.0,
    countryName: LOCATION.COUNTRY_NAME,
  });
  const [show, setShow] = useState(false);
  const [target, setTarget] = useState(null);
  const [selectedSubCategory, setSelectedSubCategory] = useState("");
  const [crimesCategory, setCrimesCategory] = useState([]);
  const [startDate, setStartDate] = useState(moment().subtract(89, "days"));
  const [endDate, setEndDate] = useState(moment());
  const [startDateSelect, setStartDateSelect] = useState(
    startDate.format("YYYY-MM-DD")
  );
  const [endDateSelect, setEndDateSelect] = useState(
    endDate.format("YYYY-MM-DD")
  );
  const [selectedRange, setSelectedRange] = useState("");
  const [country, setCountry] = useState("");
  const [location, setLocation] = useState("");

  useEffect(() => {
    const getGeocode = async () => {
      const { google } = window;
      if (google && center.lat && center.lng) {
        const geocoder = new google.maps.Geocoder();
        const latLng = new google.maps.LatLng(center.lat, center.lng);
        try {
          const results = await new Promise((resolve, reject) => {
            geocoder.geocode({ location: latLng }, (results, status) => {
              if (status === "OK" && results[0]) {
                resolve(results);
              } else {
                reject(status);
              }
            });
          });

          // Get country name from address components
          const addressComponents = results[0].address_components;
          const countryComponent = addressComponents.find((component) =>
            component.types.includes("country")
          );

          if (countryComponent) {
            setCountry(countryComponent.long_name); // Set country name
          }

          // Get the full formatted address
          const formattedAddress = results[0]?.formatted_address;
          setLocation(formattedAddress); // Set full location
        } catch (error) {
          console.error("Error fetching country and location:", error);
        }
      }
    };

    getGeocode();
  }, [center]);

  function getLatLong() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setCenter({ lat: latitude, lng: longitude });
          if (userToken) {
            location && getCurrentLocation(location, latitude, longitude);
          }
        },
        (error) => {
          setCenter({
            location: LOCATION.LOC,
            lat: LOCATION.LAT,
            lng: LOCATION.LONG,
            countryName: LOCATION.COUNTRY_NAME,
          });
          console.error("Error obtaining geolocation: ", error);
        }
      );
    } else {
      setCenter({
        location: LOCATION.LOC,
        lat: LOCATION.LAT,
        lng: LOCATION.LONG,
        countryName: LOCATION.COUNTRY_NAME,
      });
    }
  }

  useEffect(() => {
    getLatLong();
  }, []);

  async function getCurrentLocation(location, lat, lng) {
    let requestData = new FormData();
    requestData.append("current_location", location);
    requestData.append("current_latitude", parseFloat(lat));
    requestData.append("current_longitude", parseFloat(lng));

    const dataObject = {};
    requestData.forEach((value, key) => {
      dataObject[key] = value;
    });
    const urlEncodedData = qs.stringify(dataObject);
    await SublyApi.currentLocation(userToken, urlEncodedData).then(
      (response) => {
        if (response && response.status_code === STATUS_CODES.SUCCESS) {
        }
      }
    );
  }

  useEffect(() => {
    const selectedIds =
      selectedSubCategory && selectedSubCategory.length > 0
        ? selectedSubCategory.map((subCategory) => subCategory.id).join(",")
        : "";

    mapListShow(
      center.lat,
      center.lng,
      startDateSelect,
      selectedRange,
      endDateSelect,
      selectedIds
    );
    recentReportList(center.lat, center.lng);
  }, [center]);

  const handleApply = async (event, picker) => {
    const start = picker.startDate.format("YYYY-MM-DD");
    const end = picker.endDate.format("YYYY-MM-DD");
    setStartDateSelect(start);
    setEndDateSelect(end);

    if (picker.chosenLabel) {
      const days = parseInt(picker.chosenLabel.match(/\d+/));
      if (!isNaN(days)) {
        setSelectedRange(days);
      }
    }

    const selectedIds =
      selectedSubCategory && selectedSubCategory.length > 0
        ? selectedSubCategory.map((subCategory) => subCategory.id).join(",")
        : "";

    // Call mapListShow with the formatted dates
    await mapListShow(
      center.lat,
      center.lng,
      start,
      selectedRange,
      end,
      selectedIds
    );
  };

  const ranges = {
    "Last 90 Days": [moment().subtract(89, "days"), moment()],
    "Last 60 Days": [moment().subtract(59, "days"), moment()],
    "Last 30 Days": [moment().subtract(29, "days"), moment()],
    "Last Month": [
      moment().subtract(1, "month").startOf("month"),
      moment().subtract(1, "month").endOf("month"),
    ],
  };

  const handleAddressSelect = (place) => {
    if (place.geometry) {
      const latitude = place.geometry.location.lat();
      const longitude = place.geometry.location.lng();
      setCenter({ lat: latitude, lng: longitude });

      const selectedIds =
        selectedSubCategory && selectedSubCategory.length > 0
          ? selectedSubCategory.map((subCategory) => subCategory.id).join(",")
          : "";

      mapListShow(
        latitude,
        longitude,
        startDateSelect,
        selectedRange,
        endDateSelect,
        selectedIds
      );
    }
  };

  const handleInputChange = (e) => {
    if (!e.target.value) {
      // Call getLatLong if the input is empty
      getLatLong();
    }
  };

  useEffect(() => {
    // userToken && dispatch(userDetailData(userToken));
  }, []);

  useEffect(() => {
    if (center.lat !== 0 && center.lng !== 0) {
      mapListShow(center.lat, center.lng);
      recentReportList(center.lat, center.lng);
    }
    crimeCategory();
    handleCircleClick();
  }, []);

  // function for get category list api calling
  async function crimeCategory() {
    await SublyApi.crimeCategoryList(userToken).then((response) => {
      const errormsg = response && response.data && response.data.message;
      if (response && response.status_code === STATUS_CODES.SUCCESS) {
        setCrimesCategory(response && response.data);
      } else if (
        (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
          response?.data?.error_type === "ACCOUNT_DISABLED") ||
        (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
          response?.data?.error_type === "SESSION_EXPIRED") ||
        (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
          response?.data?.error_type === "INVALID_TOKEN")
      ) {
        dispatch(userLogoutClear());
        navigate("/");
      } else {
        Toster(t(errormsg), "error");
      }
    });
  }

  // function for get report map list api calling
  async function mapListShow(
    latitude = "",
    longitude = "",
    sdate = "",
    duration = "",
    edate = "",
    categoryId = ""
  ) {
    if (latitude === 0 || longitude === 0) {
      return;
    }
    const response = await SublyApi.getReportMapList(
      "",
      latitude,
      longitude,
      sdate,
      duration,
      edate,
      categoryId
    );

    const errormsg = response && response.data && response.data.message;
    if (response && response.status_code === STATUS_CODES.SUCCESS) {
      const reports = response && response.data && response.data.reports;
      if (reports && reports.length > 0) {
        setMapList(reports);
        // setCenter({
        //   lat: parseFloat(reports[0].latitude),
        //   lng: parseFloat(reports[0].longitude),
        // });
      } else {
        // Handle the case when no reports are found, without showing an error
        setMapList([]);
      }
    } else {
      Toster(t(errormsg), "error");
    }
  }

  // function for get recent report map list api calling
  async function recentReportList(
    latitude = "",
    longitude = "",
    sdate = "",
    duration = "",
    edate = "",
    categoryId = "",
    flag = FLAG.FLAG_ADD
  ) {
    if (latitude === 0 || longitude === 0) {
      return;
    }
    const response = await SublyApi.getReportMapList(
      "",
      latitude,
      longitude,
      startDateSelect,
      duration,
      endDateSelect,
      categoryId,
      flag
    );

    const errormsg = response && response.data && response.data.message;
    if (response && response.status_code === STATUS_CODES.SUCCESS) {
      const reports = response && response.data && response.data.reports;
      if (reports && reports.length > 0) {
        setMapRecentList(reports);
        // setCenter({
        //   lat: parseFloat(reports[0].latitude),
        //   lng: parseFloat(reports[0].longitude),
        // });
      } else {
        // Handle the case when no reports are found, without showing an error
        setMapRecentList([]);
      }
    } else {
      Toster(t(errormsg), "error");
    }
  }

  const handleCircleClick = () => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        setCenter({ lat: latitude, lng: longitude });
      },
      (error) => {
        console.error("Error fetching location: ", error);
      }
    );
  };

  const TimeLineViewDetails = (id) => {
    navigate(`/report/${id}`);
  };

  return (
    <div className="main">
      <Container>
        <Row className="fullWidth">
          <Col lg={4} md={12} sm={12}>
            {" "}
            <div className={styles.location}>
              <Autocomplete
                placeApiKey={process.env.REACT_APP_GOOGLE_PLACE_API_KEY}
                onLoad={(auto) => {
                  autocomplete.current = auto;
                }}
                onPlaceChanged={() => {
                  const place = autocomplete.current.getPlace();
                  handleAddressSelect(place);
                }}
              >
                <div className={styles.searchDiv}>
                  <Form.Control
                    type="text"
                    placeholder={t("SEARCH_ADDRESS")}
                    onChange={handleInputChange}
                  />
                  <IoSearchSharp />
                </div>
              </Autocomplete>
            </div>
          </Col>

          <Col lg={4} md={12} sm={12}>
            <div className="datepickerRange">
              <DateRangePicker
                initialSettings={{
                  startDate: startDate.toDate(),
                  endDate: endDate.toDate(),
                  ranges: ranges,
                  opens: "right",
                  alwaysShowCalendars: true,
                  maxDate: moment().toDate(),
                  locale: {
                    format: "DD/MM/YYYY",
                  },
                }}
                onApply={handleApply}
              >
                <input type="text" className="form-control" />
              </DateRangePicker>
            </div>
          </Col>

          <Col lg={4} md={12} sm={12}>
            {" "}
            <div className={`${styles.reportSelect} tooltipCls`}>
              <Button
                ref={target}
                onClick={(event) => {
                  setShow(!show);
                  setTarget(event.target);
                }}
              >
                <div className={styles.reportManage}>
                  <MdArrowDropDown />
                  <p>{t("ALL_CRIME")}</p>
                </div>
              </Button>
              <Overlay
                target={target}
                show={show}
                rootClose={true}
                placement="bottom"
                onHide={() => setShow(false)}
              >
                {(props) => (
                  <Tooltip
                    id="overlay-example"
                    {...props}
                    className="tooltipSetCrime multipleCrime"
                  >
                    <MultipleCategoryModal
                      setShow={setShow}
                      startDateSelect={startDateSelect}
                      selectedRange={selectedRange}
                      endDateSelect={endDateSelect}
                      crimesCategory={crimesCategory}
                      setSelectedSubCategory={setSelectedSubCategory}
                      selectedSubCategory={selectedSubCategory}
                      mapListShow={mapListShow}
                      center={center}
                    />
                  </Tooltip>
                )}
              </Overlay>
            </div>
          </Col>
        </Row>

        <div style={{ position: "relative" }}>
          <div
            style={{
              position: "absolute",
              left: "20px",
              bottom: "20px",
              width: "42px",
              height: "42px",
              borderRadius: "50%",
              backgroundColor: "black",
              cursor: "pointer",
              zIndex: 10,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            onClick={handleCircleClick}
          >
            <img
              src={CurrentLocation}
              style={{ width: "25px", height: "25px" }}
              alt="Current Location"
            />
          </div>
          <MapViewZoom
            center={center}
            mapContainerStyle={{
              height: "300px",
              width: "100%",
            }}
            markers={mapList}
            isApplyZoomLevel={true}
          />
        </div>
        <div className={styles.mapContent}>
          <h2>{t("MAP_PARA")}</h2>
          <p>{t("CRIME_SPOTTER")}</p>
          <p>{t("THIS_MAP")}</p>
          <Button type="button" onClick={() => navigate("/report")}>
            {t("REPORT")}
          </Button>
          <h4>
            {t("RECENT_REPORT")} {country}
          </h4>
          <div className={styles.mapShowList}>
            {mapRecentList.length > 0 &&
              mapRecentList.map((mapRecentList) => {
                return (
                  <div key={mapRecentList.reportID} className={styles.mapShow}>
                    <MapView
                      center={{
                        lat: Number(mapRecentList.latitude),
                        lng: Number(mapRecentList.longitude),
                      }}
                      zoom={15}
                      mapContainerStyle={{
                        height: "188px",
                        width: "100%",
                        border: "1px solid #b6b6b6",
                        borderRadius: "5px",
                      }}
                      markers={[mapRecentList]}
                    />
                    <div
                      className={styles.reportDataShow}
                      onClick={() =>
                        TimeLineViewDetails(mapRecentList.reportID)
                      }
                      style={{ cursor: "pointer" }}
                    >
                      <strong>
                        {mapRecentList.category_name == undefined ||
                        mapRecentList.category_name == "" ? (
                          ""
                        ) : (
                          <>
                            {mapRecentList.category_name}
                            &nbsp; - &nbsp;
                          </>
                        )}
                      </strong>
                      {mapRecentList.location == undefined ||
                      mapRecentList.location == "" ? (
                        ""
                      ) : (
                        <>
                          {mapRecentList.location}
                          &nbsp; - &nbsp;
                        </>
                      )}
                      {mapRecentList.date} {t("At")}{" "}
                      {mapRecentList.time
                        ? formatTime(mapRecentList.time)
                        : t("UNKNOWN")}
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </Container>
    </div>
  );
}
export default MapListReport;
