import Modal from "react-bootstrap/Modal";
import styles from "../../YourReport/YourReport.module.css";
import { useTranslation } from "react-i18next";
import { RxCross2 } from "react-icons/rx";
import Security_icon from "../../../../assets/Images/Security_announcement.svg";
import VerifyOtp from "./VerifyOtp";

function AlertVerify({
  showModal,
  onClose,
  verifyType,
  callApi,
  verifyModal,
  setVerifyModal,
  email,
  phoneNo,
  dialCode,
  countryCode,
  disable,
  setVerifyType,
}) {
  const { t } = useTranslation();

  const handleContinue = () => {
    callApi();
  };

  return (
    <>
      <Modal
        className="mainModule"
        show={showModal}
        onHide={() => {
          onClose();
        }}
      >
        <div className="MainBox">
          <div className="header">
            <span className="alertCross">
              <RxCross2
                onClick={() => {
                  onClose();
                }}
              />
            </span>
          </div>
          <div className="hadingMainDiv">
            <div className={styles.securityIconDiv}>
              <span>
                <img src={Security_icon} />
              </span>
            </div>

            <div className={styles.deleteHeading}>
              <h2>{t("PLEASE_NOTE_ALERT")}</h2>
              <p>
                {verifyType === "email" ? t("VERIFY_NEED") : t("VERIFY_NUM")}
              </p>
            </div>

            <div className={styles.deleteBtn}>
              <button
                className={styles.deleteContinue}
                onClick={handleContinue}
                type="submit"
                disabled={disable}
              >
                {t("CONFIRM_VERIFY")}
              </button>
              <button
                className={styles.deleteCancel}
                onClick={() => {
                  onClose();
                  setVerifyType("password");
                }}
                type="button"
              >
                {t("CANCEL")}
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <VerifyOtp
        verifyModal={verifyModal}
        setVerifyModal={setVerifyModal}
        verifyType={verifyType}
        email={email}
        phoneNo={phoneNo}
        dialCode={dialCode}
        countryCode={countryCode}
        setVerifyType={setVerifyType}
      />
    </>
  );
}

export default AlertVerify;
