import styles from "../Login/Login.module.css";
import "../../assets/Styles/Common.css";
import { Container, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { TfiEmail } from "react-icons/tfi";
import { TfiMobile } from "react-icons/tfi";
import { NavLink } from "react-router-dom";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import VerifyModal from "../VerifyModal/VerifyModal";
import AuthInput from "../../commonComponent/AuthInputs";
import AuthButton from "../../commonComponent/CommonButton/AuthButton";
import { removeExtraPlusSign } from "../../utils/removeExtraPlusSigns";
import SublyApi from "../../helpers/Api";
import { STATUS_CODES } from "../../utils/statusCode";
import Loader from "../../utils/Loader/Loader";
import Toster from "../../utils/Toaster";
import { Validation, validateNumber } from "../../utils/Validation";
import {
  handleKeyPress,
  allowCharLimit,
  onInputChange,
} from "../../utils/manageConstant";

//---------function for signup----------
function Signup() {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    control,
    clearErrors,
    formState: { errors },
  } = useForm();

  const [emailActive, setEmailActive] = useState(true);
  const [phoneNo, setPhoneNo] = useState("");
  const [dialCode, setDialCode] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [verifyModal, setVerifyModal] = useState(false);
  const [formDataSet, setFormDataSet] = useState({});
  const [loader, setLoader] = useState(false);
  const [firstName, setFirstName] = useState("");

  const handleChangeClick = () => {
    setEmailActive(!emailActive);
  };

  // ------function for send verification code api calling------
  const onSubmit = async (formdata) => {
    let requestData = new FormData();
    requestData.append(
      "name",
      formdata ? formdata.name && formdata.name.trim() : ""
    );

    if (emailActive) {
      requestData.append(
        "email",
        formdata ? formdata.email && formdata.email.trim() : ""
      );
    } else {
      requestData.append(
        "dial_code",
        dialCode ? removeExtraPlusSign(dialCode) : ""
      );
      requestData.append("country_code", countryCode ? countryCode : "");
      requestData.append("phone", phoneNo ? phoneNo : "");
    }
    requestData.append(
      "password",
      formdata ? formdata.password && formdata.password.trim() : ""
    );
    requestData.append(
      "confirmpass",
      formdata
        ? formdata.confirmPassword && formdata.confirmPassword.trim()
        : ""
    );
    requestData.append("social_type", "");
    setLoader(true);
    await SublyApi.sendCode(requestData).then((response) => {
      setLoader(false);
      const msg = response && response.message;
      const errormsg = response && response.data && response.data.message;
      if (response && response.status_code === STATUS_CODES.SUCCESS) {
        Toster(t(msg), "success");
        setFormDataSet(formdata);
        setVerifyModal(true);
      } else {
        Toster(t(errormsg), "error");
        setFormDataSet({});
      }
    });
  };

  return (
    <div className="main">
      {loader && <Loader />}
      <Container>
        <div className="topSpace">
          <div className={styles.LoginPage}>
            <h1>
              <span>{t("CREATE")}</span> {t("AN_ACCOUNT")}
            </h1>
            <h2>{t("CREATE_ACCOUNT")}</h2>
          </div>
          <div className={styles.formBlock}>
            <Form onSubmit={handleSubmit(onSubmit)} className="formSection">
              {/* name input field */}
              <AuthInput
                label={t("NAME")}
                name="name"
                type="text"
                register={register}
                errors={errors}
                className="kk"
                value={firstName}
                onInputChange={(e) => allowCharLimit(e, setFirstName)}
                validationRules={Validation.name}
                placeholder={t("ENTER_NAME")}
              />
              <div className={styles.SignupText}>
                <p>{t("SIGNUP_WITH")}</p>
                <div>
                  <span
                    className={
                      emailActive ? styles.TextActive : styles.EmailText
                    }
                    onClick={() => handleChangeClick()}
                  >
                    <TfiEmail />
                    {t("EMAIL")}
                  </span>
                  <span className={styles.border}></span>
                  <span
                    onClick={() => handleChangeClick()}
                    className={
                      !emailActive ? styles.TextActive : styles.EmailText
                    }
                  >
                    <TfiMobile />
                    {t("SMS")}
                  </span>
                </div>
              </div>
              {emailActive ? (
                <>
                  {/* email input field */}
                  <AuthInput
                    label={t("EMAIL_ADDRESS_LOGIN")}
                    name="email"
                    type="text"
                    register={register}
                    errors={errors}
                    onInputChange={(e) => onInputChange(e, setValue)}
                    validationRules={Validation.email}
                    placeholder={t("EMAIL_ADDRESS")}
                  />
                  <div className={styles.SignupTextCode}>
                    <p>{t("SAND_EMAIL")}</p>
                  </div>
                </>
              ) : (
                <>
                  <AuthInput
                    label={t("PHONE_NUMBER")}
                    name="phoneNumber"
                    type="phone"
                    control={control}
                    errors={errors}
                    phoneInputProps={{
                      country: "za",
                      countryCodeEditable: false,
                      dialCode,
                      phoneNo,
                      setCountryCode,
                      setDialCode,
                      setPhoneNo,
                      clearErrors: clearErrors,
                    }}
                    validate={() => validateNumber(phoneNo)}
                  />
                  <div className={styles.SignupTextCode}>
                    <p>{t("SAND_SMS")}</p>
                  </div>
                </>
              )}
              {/* password input field */}
              <AuthInput
                label={t("PASSWORD")}
                name="password"
                type="password"
                placeholder={t("ENTER_PASSWORD")}
                register={register}
                errors={errors}
                onInputChange={(e) => onInputChange(e, setValue)}
                validationRules={Validation.password}
              />
              {/* confirm password input field */}
              <AuthInput
                placeholder={t("ENTER_CONFIRM_PASSWORD")}
                label={t("CONFIRM_PASS")}
                name="confirmPassword"
                type="password"
                register={register}
                errors={errors}
                onInputChange={(e) => onInputChange(e, setValue)}
                validationRules={Validation.confirmPassword}
                validate={(value) =>
                  value === watch("password") || `${t("PASS_NOT_MATCH")}`
                }
              />

              {/* common button */}
              <AuthButton type="submit" label={t("SIGNUP")} />
              <div className={styles.bottomText}>
                <p>
                  {t("ONBOARD")} <NavLink to="/login"> {t("LOGIN")}</NavLink>
                </p>
              </div>
            </Form>
          </div>
        </div>
      </Container>

      <VerifyModal
        verifyModal={verifyModal}
        setVerifyModal={setVerifyModal}
        formDataSet={formDataSet}
        emailActive={emailActive}
        phoneNo={phoneNo}
        dialCode={dialCode}
        countryCode={countryCode}
      />
    </div>
  );
}
export default Signup;
