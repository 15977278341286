import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Sidebar from "../Sidebar";
import UserSearchModule from "../../../commonComponent/UserSearchInput";
import styles from "../Followers/Followers.module.css";
import SublyApi from "../../../helpers/Api";
import { useDispatch, useSelector } from "react-redux";
import { STATUS_CODES } from "../../../utils/statusCode";
import Toster from "../../../utils/Toaster";
import Loader from "../../../utils/Loader/Loader";
import {
  updateSettingCount,
  userDetailData,
  userLogoutClear,
} from "../../../store/slices/Userslice";
import { useNavigate } from "react-router-dom";
import { isLimit, offset } from "../../../utils/Constants";
import { getUserById } from "../../../firebase/FireBaseChat";
import defaultImage from "../../../assets/Images/user_profile_Default_image.png";

const Following = () => {
  const { t } = useTranslation();
  const { userToken, currentUser } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [loader, setLoader] = useState(false);
  const [followersList, setFollowersList] = useState([]);
  const [followersData, setFollowersData] = useState([]);
  const [otherUserList, setUserList] = useState([]);
  const [totalReports, setTotalReports] = useState("");

  const handleSearch = (e) => {
    const searchText = e.target.value;
    setSearch(searchText);
  };

  async function getOtherUserListFirebase(followers) {
    followers.map(async (item) => {
      const otherUserInfo = await getUserById(item.userID);
      if (otherUserInfo) {
        setUserList((pre) => [...pre, otherUserInfo]);
      }
    });
  }
  useEffect(() => {
    if (followersList?.length > 0) {
      setUserList([]);
      getOtherUserListFirebase(followersList);
    }
  }, [followersList]);

  const TimeLineViewProfile = (id) => {
    navigate(`/profile-detail/${id}`);
  };

  async function getFollowingDetails(userID, page = 1) {
    setLoader(true);
    await SublyApi.getFollowing(
      userToken,
      userID,
      isLimit,
      offset + (page - 1) * isLimit,
      search
    ).then((response) => {
      setLoader(false);
      const errormsg = response && response.data && response.data.message;
      if (response && response.status_code === STATUS_CODES.SUCCESS) {
        const sortedReports = response.data.following;
        setTotalReports(response?.data?.total_following);

        dispatch(
          updateSettingCount({
            keyName: "following_count",
            value: response?.data?.total_following,
          })
        );

        setFollowersData(response.data);

        if (sortedReports && sortedReports.length > 0) {
          if (page === 1) {
            setFollowersList(sortedReports);
          } else {
            setFollowersList((prev) => [...prev, ...sortedReports]);
          }
        } else {
          setFollowersList([]);
        }
      } else if (
        (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
          response?.data?.error_type === "ACCOUNT_DISABLED") ||
        (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
          response?.data?.error_type === "SESSION_EXPIRED") ||
        (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
          response?.data?.error_type === "INVALID_TOKEN")
      ) {
        dispatch(userLogoutClear());
        navigate("/");
      } else {
        Toster(t(errormsg), "error");
      }
    });
  }

  useEffect(() => {
    if (currentUser?.userID) {
      getFollowingDetails(currentUser.userID);
    }
  }, [search, currentUser?.userID]);

  useEffect(() => {
    userToken && dispatch(userDetailData(userToken));
  }, []);

  async function FollowersCount(followId) {
    // setLoader(true);
    await SublyApi.followingUpdate(userToken, followId).then((response) => {
      // setLoader(false);
      const errormsg = response && response.data && response.data.message;

      if (response && response.status_code === STATUS_CODES.SUCCESS) {
        getFollowingDetails(currentUser.userID);
        const successMessage =
          followersList.find((user) => user.userID === followId)?.is_follow ===
          "1"
            ? t("PROFILE_UNFOLLOW_SUCCESSFULL")
            : t("PROFILE_FOLLOW_SUCCESSFULL");
        Toster(successMessage, "success");
      } else if (
        (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
          response?.data?.error_type === "ACCOUNT_DISABLED") ||
        (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
          response?.data?.error_type === "SESSION_EXPIRED") ||
        (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
          response?.data?.error_type === "INVALID_TOKEN")
      ) {
        dispatch(userLogoutClear());
        navigate("/");
      } else {
        Toster(t(errormsg), "error");
      }
    });
  }

  const loadMoreReports = () => {
    const nextPage = page + 1;
    setPage(nextPage);
    getFollowingDetails(currentUser.userID, nextPage);
  };

  return (
    <>
      {loader && <Loader />}
      <div className="main">
        <div className="spaceTopSet">
          <Container>
            <Row>
              <Col lg={3} md={4}>
                <Sidebar />
              </Col>

              <Col lg={9} md={8} className="rightPart">
                <div className="rightTopPart">
                  <h2>
                    {t("FOLLOWING")}{" "}
                    <span>
                      <span>({followersData.total_following || 0})</span>
                    </span>
                  </h2>
                  <UserSearchModule hadleSearch={handleSearch} value={search} />
                </div>

                <div className={styles.scrollTopFollowing}>
                  {followersList && followersList.length > 0 ? (
                    followersList.map((item, index) => (
                      <div className={styles.dashboardInfo} key={index}>
                        <div
                          className={styles.dashboardImg}
                          style={{ cursor: "pointer" }}
                          onClick={() => TimeLineViewProfile(item.userID)}
                        >
                          <img
                            src={
                              item?.profile_picture
                                ? item?.profile_picture
                                : defaultImage
                            }
                            alt="img"
                          />
                          {otherUserList?.length > 0 &&
                            otherUserList.find((obj) => obj.id === item.userID)
                              ?.online == "isOnline" && (
                              <div className="greenIconOnlineMain">
                                <span></span>
                              </div>
                            )}
                        </div>
                        <div className={styles.dashboardText}>
                          <p
                            onClick={() => TimeLineViewProfile(item.userID)}
                            style={{ cursor: "pointer" }}
                          >
                            @{item.username}
                          </p>
                          <h3
                            onClick={() => TimeLineViewProfile(item.userID)}
                            style={{ cursor: "pointer" }}
                          >
                            {item.name}
                          </h3>

                          <div className={styles.followersButtonMainDiv}>
                            <div className={styles.dashboardInfoBtn}>
                              {currentUser.userID !== item.userID && (
                                <>
                                  {item.is_follow === "1" ? (
                                    <button
                                      className={styles.followButton}
                                      onClick={() => {
                                        FollowersCount(item.userID);
                                      }}
                                    >
                                      {t("FOLLOWING")}
                                    </button>
                                  ) : (
                                    <button
                                      className={styles.unfollowButton}
                                      onClick={() => {
                                        FollowersCount(item.userID);
                                      }}
                                    >
                                      {t("UNFOLLOWING")}
                                    </button>
                                  )}
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="records_Found">
                      <h2>{t("NO_REC_FOUND")}</h2>
                    </div>
                  )}

                  {followersList?.length < totalReports ? (
                    <div className="loadMoreSection">
                      <button className="loadMoreBtn" onClick={loadMoreReports}>
                        {t("LOAD_MORE")}
                      </button>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
};

export default Following;
