import { Col, Container, Row } from "react-bootstrap";
import Sidebar from "../Sidebar";
import styles from "./AlertLocation.module.css";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import UserCommonButton from "../../../commonComponent/CommonButton/UserCommonButton";
import CrudIcon from "../../../commonComponent/CrudIcon";
import SublyApi from "../../../helpers/Api";
import { useDispatch, useSelector } from "react-redux";
import { STATUS_CODES } from "../../../utils/statusCode";
import { useEffect, useState } from "react";
import AlertDeletePopup from "./AlertDeletePopup";
import Loader from "../../../utils/Loader/Loader";
import {
  userDetailData,
  userLogoutClear,
} from "../../../store/slices/Userslice";

function AlertLocation() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userToken } = useSelector((state) => state.user);
  const [alertLocationList, SetAlertLocationList] = useState();
  const [alertId, setAlertId] = useState();
  const [loader, setLoader] = useState(false);
  const [disable, setDisable] = useState(false);

  useEffect(() => {
    userToken && dispatch(userDetailData(userToken));
  }, []);

  // alert delete state
  const [deleteAlertShow, setDeleteAlertShow] = useState(false);
  const DeleteHandleAlertShow = () => setDeleteAlertShow(true);
  const DeleteHandleAlertClose = () => setDeleteAlertShow(false);

  // getAlertLoaction
  async function AlertLocationList() {
    setLoader(true);
    await SublyApi.getAlertLoction(userToken).then((response) => {
      setLoader(false);
      const errormsg = response && response.data && response.data.message;
      if (
        response &&
        response.data &&
        response.status_code === STATUS_CODES.SUCCESS
      ) {
        SetAlertLocationList(response.data.alert_locations);
      } else if (
        (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
          response?.data?.error_type === "ACCOUNT_DISABLED") ||
        (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
          response?.data?.error_type === "SESSION_EXPIRED") ||
        (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
          response?.data?.error_type === "INVALID_TOKEN")
      ) {
        dispatch(userLogoutClear());
        navigate("/");
      }
    });
  }

  useEffect(() => {
    AlertLocationList();
  }, []);

  const onToggleDelete = (id) => {
    DeleteHandleAlertShow();
    setAlertId(id);
  };

  const onToggleEdit = (item) => {
    navigate("/add-location", {
      state: {
        id: item,
        isUpdate: true,
      },
    });
  };

  return (
    <>
      <div className="main">
        {loader && <Loader />}
        <div className="spaceTopSet">
          <Container>
            <Row>
              <Col lg={3} md={4}>
                <Sidebar />
              </Col>
              <Col lg={9} md={8} className="rightPart">
                <div className="rightTopPart">
                  <h2>{t("ALERT_LOCATION")}</h2>
                </div>
                <div className={styles.alertLocation}>
                  <div className={styles.alertLocationText}>
                    <h5>{t("LOCATION_TEXT")}</h5>
                    {alertLocationList && alertLocationList.length > 0 ? (
                      ""
                    ) : (
                      <h6>{t("ADD_LOCATION_TEXT")}</h6>
                    )}
                  </div>

                  <div className={styles.scrollTopAlertLocation}>
                    {/* map add */}
                    {alertLocationList &&
                      alertLocationList.map((item, index) => {
                        return (
                          <div className={styles.alertSection} key={index}>
                            <div className={styles.alertText}>
                              <h5>{item.location_title}</h5>
                              <h6>{item.address}</h6>
                            </div>
                            {/* common crud icon */}
                            <CrudIcon
                              commentId={item.alertLocationID}
                              onToggleDelete={() =>
                                onToggleDelete(item.alertLocationID)
                              }
                              onToggleEdit={() => onToggleEdit(item)}
                            />
                          </div>
                        );
                      })}
                  </div>

                  <div className={styles.locationBox}>
                    <UserCommonButton
                      type="button"
                      onClick={() => navigate("/add-location")}
                      label={t("ADD_TEXT")}
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <AlertDeletePopup
        AlertLocationList={AlertLocationList}
        DeleteHandleAlertClose={DeleteHandleAlertClose}
        deleteAlertShow={deleteAlertShow}
        alertId={alertId}
        alertLocationList={alertLocationList}
        disable={disable}
        setDisable={setDisable}
        setLoader={setLoader}
      />
    </>
  );
}
export default AlertLocation;
