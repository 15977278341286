import React, { useState, useEffect } from "react";
import { GoogleMap, Marker } from "@react-google-maps/api";
import MapPin from "../../assets/Images/map_circle.png";

export default function MapReportView({
  position,
  zoom,
  setPosition,
  mapContainerStyle,
}) {
  const [map, setMap] = useState(null);
  const [mapCenter, setMapCenter] = useState(position); // State to hold map center

  // Update map center when position prop changes (e.g., if position is updated externally)
  useEffect(() => {
    setMapCenter(position);
  }, [position]);

  // Function to update marker position at the center of the map viewport
  const updateMarkerPosition = () => {
    if (map) {
      const center = map.getCenter().toJSON();
      setPosition(center);
      setMapCenter(center);
    }
  };

  return (
    <div style={{ position: "relative" }}>
      {mapCenter && <GoogleMap
        mapContainerStyle={mapContainerStyle}
        center={mapCenter}
        zoom={zoom}
        draggable={true}
        onDragEnd={() => {
          updateMarkerPosition();
        }}
        onLoad={(map) => setMap(map)}
        options={{
          fullscreenControl: false,
        }}
      ></GoogleMap>}
      <div className="center-marker">
        <img src={MapPin} alt="img" />
      </div>
    </div>
  );
}
