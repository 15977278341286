import { Col, Container, Row } from "react-bootstrap";
import styles from "./Content.module.css";
import { useTranslation } from "react-i18next";
import SublyApi from "../../helpers/Api";
import { CONTENT_TYPE } from "../../utils/Constants";
import { useSelector } from "react-redux";
import Toster from "../../utils/Toaster";
import { STATUS_CODES } from "../../utils/statusCode";
import { useEffect, useState } from "react";
import { TextFormate } from "../../utils/removeExtraPlusSigns";
import Loader from "../../utils/Loader/Loader";
import Sidebar from "../UserModule/Sidebar";

function HowItsWork() {
  const { currentUser } = useSelector((state) => state.user);
  const { t } = useTranslation();
  const [termsData, setTermsData] = useState();
  const [loader, setLoader] = useState(false);

  // function for get terms and condition api calling
  async function termsCondition() {
    setLoader(true);
    await SublyApi.contentType(CONTENT_TYPE.HOW_IT_WORKS).then((response) => {
      setLoader(false);
      const errormsg = response && response.data && response.data.message;
      if (response && response.status_code === STATUS_CODES.SUCCESS) {
        setTermsData(
          response &&
            response.data &&
            response.data.content &&
            response.data.content.how_to_works
        );
      } else {
        Toster(t(errormsg), "error");
      }
    });
  }

  useEffect(() => {
    termsCondition();
  }, []);

  return (
    <div className="main">
      {loader && <Loader />}
      <div className="spaceTopSet">
        <Container>
          <Row>
            <Col
              lg={Object.keys(currentUser).length !== 0 ? 9 : 12}
              md={Object.keys(currentUser).length !== 0 ? 8 : 12}
              className={
                Object.keys(currentUser).length !== 0
                  ? "rightPart"
                  : "centerSet"
              }
            >
              <div className="rightTopPart">
                <div className={styles.searchBoxCls}>
                  <h2>{t("HOW_WORKS")}</h2>
                </div>
              </div>

              <div className="reportIncidentOverlay">
                <div className="reportIncident">
                  <p
                    className={styles.termsCondition}
                    dangerouslySetInnerHTML={{
                      __html: TextFormate(termsData),
                    }}
                  ></p>
                </div>
              </div>
            </Col>
            <Col lg={4} md={4}>
              <div className={styles.reportRightAd}>
                Google Adsense Advert 336x280
              </div>{" "}
              <div className={styles.reportRightAd}>
                Google Adsense Advert 336x280
              </div>{" "}
              <div className={styles.reportRightAd}>
                Google Adsense Advert 336x280
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default HowItsWork;
