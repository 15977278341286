import { Col, Container, Row } from "react-bootstrap";
import Sidebar from "../Sidebar";
import styles from "./Dashboard.module.css";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { IoSettingsOutline } from "react-icons/io5";
import { NavLink, useNavigate } from "react-router-dom";
import { formatLocalTime } from "../../../utils/manageConstant";
import userIcon from "../../../assets/Images/dashbord_user.svg";
import defaultImage from "../../../assets/Images/user_profile_Default_image.png";

//---function for user dashboard---------
function Dashboard() {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const { userData } = useSelector((state) => state.user);

  const viewDetailsUserProfile = () => {
    navigate(`/profile`);
  };

  const viewReport = () => {
    navigate(`/your-report`);
  };

  const followers = () => {
    navigate(`/followers`);
  };

  const following = () => {
    navigate(`/following`);
  };

  return (
    <>
      <div className="main">
        <div className="spaceTopSet">
          <Container>
            <Row>
              <Col lg={3} md={4}>
                <Sidebar />
              </Col>
              <Col lg={9} md={8} className="rightPart">
                <div className="rightTopPart">
                  <h2>{t("DASHBOARD")}</h2>
                  {/* <UserSearchModule hadleSearch={handleSearch} value={search} /> */}
                </div>
                <div className={styles.dashboardInfo}>
                  <div
                    className={styles.dashboardImg}
                    onClick={() => {
                      viewDetailsUserProfile();
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <img
                      src={
                        userData?.profile_picture
                          ? userData?.profile_picture
                          : defaultImage
                      }
                      alt="img"
                    />
                  </div>
                  <div className={styles.dashboardText}>
                    <p
                      onClick={() => {
                        viewDetailsUserProfile();
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      @{userData && userData.username}
                    </p>
                    <h3
                      onClick={() => {
                        viewDetailsUserProfile();
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      {userData && userData.name}
                    </h3>
                    <h5>
                      <span
                        onClick={() => {
                          viewReport();
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        <strong>
                          {userData &&
                            (userData.total_reports == ""
                              ? 0
                              : userData.total_reports)}
                        </strong>{" "}
                        {t("REPORTS")} |{" "}
                      </span>
                      <span
                        onClick={() => {
                          followers();
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        <strong>
                          {userData &&
                            (userData.follower_count == ""
                              ? 0
                              : userData.follower_count)}
                        </strong>{" "}
                        {t("FOLLOWERS")} |{" "}
                      </span>
                      <span
                        onClick={() => {
                          following();
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        <strong>
                          {userData &&
                            (userData.following_count == ""
                              ? 0
                              : userData.following_count)}
                        </strong>{" "}
                        {t("FOLLOWING")}
                      </span>
                    </h5>
                    <h6>
                      {userData && (
                        <>
                          {t("JOINED")}{" "}
                          {formatLocalTime(userData && userData.created_at)}{" "}
                        </>
                      )}
                      {userData && (
                        <>
                          - {t("LAST_LOGIN")}{" "}
                          {formatLocalTime(userData && userData.last_login_at)}{" "}
                        </>
                      )}
                    </h6>
                  </div>
                </div>

                <div className={styles.dashboardSpacce}>
                  <h2>
                    {t("WELCOME_BACK")}&nbsp;{userData && userData.username}
                  </h2>
                </div>
                <div className={styles.dashboardBoxes}>
                  <Row>
                    <Col lg={4} md={4} sm={4} xs={6}>
                      <NavLink
                        to="/your-report"
                        style={{ textDecoration: "none", color: "inherit" }}
                      >
                        <div className={styles.dashboardBox}>
                          <h1>
                            {userData &&
                              (userData.total_reports === ""
                                ? 0
                                : userData.total_reports)}
                          </h1>
                          <p>{t("REPORTS")}</p>
                        </div>
                      </NavLink>
                    </Col>
                    <Col lg={4} md={4} sm={4} xs={6}>
                      <NavLink
                        to="/alerts"
                        style={{ textDecoration: "none", color: "inherit" }}
                      >
                        <div className={styles.dashboardBox}>
                          <h1>
                            {userData &&
                              (userData.total_alerts == ""
                                ? 0
                                : userData.total_alerts)}
                          </h1>
                          <p>{t("ALERTS")}</p>
                        </div>
                      </NavLink>
                    </Col>

                    <Col lg={4} md={4} sm={4} xs={6}>
                      <NavLink
                        to="/comments"
                        style={{ textDecoration: "none", color: "inherit" }}
                      >
                        <div className={styles.dashboardBox}>
                          <h1>
                            {userData &&
                              (userData.total_comments == ""
                                ? 0
                                : userData.total_comments)}
                          </h1>
                          <p>{t("COMMENTS")}</p>
                        </div>
                      </NavLink>
                    </Col>
                    <Col lg={4} md={4} sm={4} xs={6}>
                      <NavLink
                        to="/alert-location"
                        style={{ textDecoration: "none", color: "inherit" }}
                      >
                        <div className={styles.dashboardBox}>
                          <h1>
                            {userData &&
                              (userData.alertlocation_count == ""
                                ? 0
                                : userData.alertlocation_count)}
                          </h1>
                          <p>{t("ALERT_LOCATION")}</p>
                        </div>
                      </NavLink>
                    </Col>
                    <Col lg={4} md={4} sm={4} xs={6}>
                      <NavLink
                        to="/profile"
                        style={{ textDecoration: "none", color: "inherit" }}
                      >
                        <div className={styles.dashboardBox}>
                          <h1>
                            <img src={userIcon} />
                          </h1>
                          <p>{t("PROFILE")}</p>
                        </div>
                      </NavLink>
                    </Col>
                    <Col lg={4} md={4} sm={4} xs={6}>
                      <NavLink
                        to="/login-settings"
                        style={{ textDecoration: "none", color: "inherit" }}
                      >
                        <div className={styles.dashboardBox}>
                          <h1>
                            {" "}
                            <IoSettingsOutline />
                          </h1>
                          <p>{t("SETTING")}</p>
                        </div>
                      </NavLink>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
}
export default Dashboard;
